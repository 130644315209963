import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';

import { CustomRichTextDocument } from '@/middleware/mappers/rich-text-body/rich-text-body';
import { Sessions } from '@/types/apis';
import {
  ArticleTeaserEntry,
  Channel,
  ComponentEntry,
  ContentFeedEntry,
  ContentFeedEntryCategory,
  ContentFeedEntryContactItem,
  ContentFeedEntrySoundpiece,
  ContentFeedLayout,
  ExternalVideo,
  FaqItemEntry,
  Filter,
  ImageAsset,
  ImageWithText,
  InternalVideo,
  Link,
  LotteryFormButton,
  MediaAsset,
  MusicAwardActEntry,
  RelatedArticleEntry,
  SliderItemEntry,
  Tag,
  TagChipType,
  TeamMember,
  TeaserGroup,
  TextLink,
  ToggleData,
} from '@/types/views/generic';

import {
  BoxTheme,
  BoxType,
  ContentFeedEntryCmsEntryType,
  ContentFeedEntryCmsEntryTypeGroup,
  ContentFeedTagCms,
  GridEntryTypeCms,
  JobEntryCms,
  PageThemeCms,
} from '../cms';

export interface Section extends ComponentEntry {
  slug?: string;
  layout?: SectionEntryLayout;
  customPadding?: boolean;
}

export enum TitleWithTextEntryCmsTitleTheme {
  Smaller = 'Kleiner Titel',
  Medium = 'Mittlerer Titel',
  Bigger = 'Grosser Titel',
  WideContent = 'Seiten Titel',
}

export enum TitleWithTextEntryCmsPosition {
  Left = 'Links',
  Center = 'Zentriert',
}

export interface TitleWithTextSectionEntry extends Section {
  title: string;
  text?: string | Document;
  theme: TitleWithTextEntryCmsTitleTheme;
  position?: TitleWithTextEntryCmsPosition;
  showTitle?: boolean;
  deactivateAdSlots?: boolean;
}

export interface LinkSectionEntry extends Section, TextLink {}

export interface SliderSectionEntry extends Section {
  sliderItems: SliderItemEntry[];
  deactivateAdSlots?: boolean;
}

export interface TeaserSectionEntry extends Section {
  title: string;
  imageDesktop: ImageAsset;
  imageMobile: ImageAsset;
  overlineText?: string;
  description?: Document;
  link?: TextLink | LotteryFormButton;
  formatOverlineText?: boolean;
  sessionDate?: string;
  sessionLocation?: string;
  sessionNumber?: string;
  descriptionClassNames?: string;
}

export type SectionEntryLayout = 'page' | 'rich-text';

export interface ImageSectionEntry extends Section {
  image: ImageAsset;
  imageMobile?: ImageAsset;
  description?: string;
  link?: Link;
  deactivateAdSlots?: boolean;
}

export interface BoxSectionEntry extends Section {
  boxTitle?: string;
  title: string;
  image?: Omit<ImageAsset, 'contentType' | 'id' | 'width' | 'height'>;
  imagePlaceholderClass?: string;
  description?: Document | JSX.Element;
  overlineText?: string;
  links: Array<TextLink | LotteryFormButton>;
  type: BoxType;
  theme: BoxTheme;
  deactivateAdSlots?: boolean;
}

export enum EmbedType {
  Brightcove = 'brightcove',
  Facebook = 'facebook',
  Spotify = 'spotify',
  Youtube = 'youtube',
  Instagram = 'instagram',
  Soundcloud = 'soundcloud',
}

export interface EmbedSectionEntry extends Section {
  title: string;
  url: string;
  embedType: EmbedType;
  instagramHtml?: string;
}

export interface GridEntry extends Section {
  title?: string;
  type: GridEntryTypeCms;
  items: ImageWithText[];
  numberOfColumns: 1 | 2 | 3 | 4 | 5 | 6;
}

export type PageHeaderEntryTheme =
  | 'category'
  | 'profile'
  | 'podcast'
  | 'formate'
  | 'event'
  | 'playlist'
  | 'show'
  | 'act';

export interface PageHeaderEntry extends Section {
  title: string;
  background?: ImageAsset;
  backgroundMobile?: ImageAsset;
  image: ImageAsset;
  description?: Document | string;
  theme: PageHeaderEntryTheme;
  areActionIconsVisible?: boolean;
  tags?: Tag[];
  toggleData?: ToggleData[];
  sessionDate?: string;
  sessionLocation?: string;
}

export interface StationHeaderSectionEntry extends Section {
  title: string;
  background?: ImageAsset;
  backgroundMobile?: ImageAsset;
  station: {
    id: string;
    slug: string;
    frequency?: string;
    logoUrl?: string;
  };
  toggleLinksData?: ToggleData[];
  playlistLink: Link;
  whatsappNumber: string;
  fallbackStreamUrl: string;
}

export interface ArticleImageHeaderEntry extends Section {
  title: string;
  image: ImageAsset;
}

export interface ArticleTextIntroEntry extends Section {
  title: string;
  lead?: CustomRichTextDocument;
  link: Link;
  publishDate: string;
  contentCategories?: string[];
  theme?: PageHeaderEntryTheme;
  profiles?: TagChipType[];
}

export interface SectionSectionEntry extends Section {
  title?: string;
  showTitle?: boolean;
  link?: TextLink;
  elements: ComponentEntry[];
  textClassNames?: string;
  deactivateAdSlots?: boolean;
}

export interface ContentFeedSectionEntry extends Section {
  title: string;
  hideTitle: boolean;
  teaserGroup: TeaserGroup;
  entries: ContentFeedEntry[];
  totalCount: number;
  contentFeedLayout: ContentFeedLayout;
  numberOfRows: number;
  entryTypeGroup: ContentFeedEntryCmsEntryTypeGroup;
  link?: TextLink;
  allowedEntryTypes?: ContentFeedEntryCmsEntryType[];
  tagsToInclude?: ContentFeedTagCms[];
  showLoadMore?: boolean;
}

export type WatchOverviewItemsType = 'meme' | 'video';

export interface WatchOverviewSectionEntry extends Section {
  itemsType: WatchOverviewItemsType;
  filters?: Filter[];
}

export interface SoundpiecesOverviewSectionEntry extends Section {
  filters?: Filter[];
  headerTitle?: string;
}

export interface ContentFeedMediaOverviewEntry extends Section {
  entryType: WatchOverviewItemsType;
  title: string;
  linkText: string;
  entries: ContentFeedEntry[];
}

export interface SoundpiecePlaylistEntry extends Section {
  title: string;
  description: string;
  tagIdsToInclude?: string[];
}

export type VideoEntry = Section & {
  video: InternalVideo | ExternalVideo;
  containerClassName?: string;
  autoPlay?: boolean;
  isUnMutedSection?: boolean;
};

export interface WatchDetailSectionEntry extends Section {
  contentItemsType: WatchOverviewItemsType;
}

export interface RichTextEntry extends Section {
  title: string;
  body: CustomRichTextDocument;
}

export interface StaticComponentSectionEntry extends Section {
  title: string;
  showTitle: boolean;
}

export interface NewsletterSectionEntry extends StaticComponentSectionEntry {
  newsletterMobileImage?: ImageAsset;
  newsletterDesktopImage?: ImageAsset;
  newsletterSuccessImage?: ImageAsset;
  newsletterErrorImage?: ImageAsset;
  deactivateAdSlots?: boolean;
}

export interface ContactFormSectionEntry extends StaticComponentSectionEntry {
  contactItems: ContentFeedEntryContactItem[];
  newsletterMobileImage?: ImageAsset;
  newsletterDesktopImage?: ImageAsset;
  newsletterSuccessImage?: ImageAsset;
  newsletterErrorImage?: ImageAsset;
}

export type WeitereChannelsSectionEntry = StaticComponentSectionEntry;

export interface TeamSectionEntry extends StaticComponentSectionEntry {
  members: TeamMember[];
  departments: Filter[];
}

export type JobEntry = JobEntryCms;

export interface JobsSectionEntry extends StaticComponentSectionEntry {
  jobs: JobEntry[];
}

export interface CategoriesAZSectionEntry extends StaticComponentSectionEntry {
  categories: ContentFeedEntryCategory[];
}

export interface ArticleTeaserContainerSectionEntry extends ComponentEntry {
  title?: string;
  link?: TextLink;
  articles: ArticleTeaserEntry[];
  deactiveTeaserLink?: boolean;
}

export interface PodcastEmbedSectionEntry extends Section {
  title?: string;
  podcastSubdomain: string;
  deactivateAdSlots?: boolean;
}

export interface GallerySectionEntry extends Section {
  title: string;
  media: MediaAsset[]; // TODO: make sure if it can be image and video,
}

export type GalleryTeaser = BoxSectionEntry;

export interface GalleriesContainerSectionEntry extends Section {
  title?: string;
  link?: TextLink;
  showTitle: boolean;
  galleryTeasers: GalleryTeaser[];
}

export interface HeroSectionEntry extends Section {
  background: MediaAsset;
  backgroundMobile: MediaAsset;
  foreground?: ImageAsset;
  foregroundMobile?: ImageAsset;
  link?: TextLink;
  showButton: boolean;
  isForegroundFullWidth?: boolean;
}

export interface DualHeroSectionEntry extends Section {
  primaryBackgroundDesktop: MediaAsset;
  primaryBackgroundMobile?: MediaAsset;
  secondaryBackgroundDesktop?: MediaAsset;
  secondaryBackgroundMobile?: MediaAsset;
  backgroundColor: string;
  textColor: string;
  image?: ImageAsset;
  text?: string;
  link?: TextLink;
  showButton: boolean;
}

export interface HeroLivestreamSectionEntry extends Section {
  title?: string;
  streamUrl: string;
  background: MediaAsset;
  backgroundMobile: MediaAsset;
  teaserImage?: ImageAsset;
  teaserImageMobile?: ImageAsset;
}

export interface FaqEntry extends ComponentEntry {
  title: string;
  titleTagComponent: string;
  slug: string;
  faqItems: FaqItemEntry[];
  link?: TextLink;
}

export interface RelatedArticlesEntry extends ComponentEntry {
  articles: RelatedArticleEntry[];
  hasMore: boolean;
  theme?: PageThemeCms;
}

export interface LotteryHeaderTextData {
  smallHeadline?: string;
  title?: string;
  description?: string;
  titleAlreadyParticipated: string;
  descriptionAlreadyParticipated: string;
  titleLogin: string;
  descriptionLogin?: string;
  titleIncompleteProfile?: string;
  descriptionIncompleteProfile: string;
  titleAlreadyWon: string;
  descriptionAlreadyWon?: string;
  titleInactive: string;
  descriptionInactive?: string;
  titleError?: string;
  descriptionError: string;
  ctaButtonText: string;
  ctaShareText: string;
  ctaLoginText: string;
  ctaInactiveText?: string;
  ctaIncompleteProfileText: string;
  titleParticipationSuccess: string;
  descriptionParticipationSuccess: string;
}

export interface LotteryHeaderSectionEntry extends Section {
  lotteryId: number;
  poolId: number;
  background: MediaAsset;
  backgroundMobile: MediaAsset;
  webRaffleStart?: string;
  webRaffleEnd?: string;
  raffleEndUrl?: string;
  raffleEndLink?: TextLink;
  textData: LotteryHeaderTextData;
  profileEndpointLink: Link;
}

export type PlaylistHeaderEntry = Omit<PageHeaderEntry, 'tags'>;

export interface PlaylistSectionEntry extends Section {
  playlistId: string;
}

export interface ChannelCarouselSectionEntry extends Section {
  currentChannel: Channel;
  channels: Channel[];
}

export type TextWithHeaderSectionEntry = Pick<
  TitleWithTextSectionEntry,
  'id' | 'componentType' | 'text' | 'title' | 'deactivateAdSlots'
>;

export interface AudioDetailEntry extends Section {
  currentEntry: ContentFeedEntrySoundpiece;
  entries: ContentFeedEntrySoundpiece[];
  link: TextLink;
}

export interface SessionsSectionEntry extends Section, Sessions {
  title: string;
  isHistory?: boolean;
}

export interface QuizSectionEntry extends Section {
  title: string;
  deactivateAdSlots?: boolean;
  displayTitle?: boolean;
  correctTitle?: string;
  correctMessage?: string;
  invalidAnswerTitle?: string;
  invalidAnswerMessage?: string;
  messageAlreadyAttended?: string;
  messageWithoutQuiz?: string;
  sweepstakeDataToken?: string;
  userNotWonTitle?: string;
  validProfileWonTitle?: string;
  loginForResultsMessage?: string;
  hideAdditionalWideboardAds: boolean;
  marketingOptInText?: string;
}

export interface MusicAwardSectionEntry extends Section {
  displayTitle?: string;
  displayData: string;
  votingStart?: string;
  votingEnd?: string;
  year?: string;
  acts: MusicAwardActEntry[];
}

export interface PageTeaserSectionEntry extends Section {
  title: string;
  description?: Document;
  links?: Array<TextLink>;
}

export interface InfoPageHeaderEntry extends Section {
  title: string;
}

export interface InfoPageContentEntry extends Section {
  content?: Document;
  mediaFiles?: Asset[];
  mediaDownloadText?: string;
}
